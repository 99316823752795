<template>
    <v-dialog v-model="visible" max-width="1300px" transition="fade-transition" :persistent="persistent">
        <WindowCard :title="title" title-icon="style" :actions="true" actions-align="right">
            <slot></slot>
            <template slot="actions">
                <v-btn :disabled="!actions_enabled" depressed color="primary" class="subtitle-2" large @click="$emit('accept')">
                    <v-icon left>done</v-icon>
                    Aceptar
                </v-btn>
                <v-btn :disabled="!actions_enabled" depressed color="primary" class="subtitle-2" large @click="$emit('cancel')">
                    <v-icon left>close</v-icon>
                    Cancelar
                </v-btn>
            </template>
        </WindowCard>
        <Message ref="message" />
    </v-dialog>
</template>

<script>
import WindowCard from '@/shared/components/WindowCard'
import Message from '@/shared/components/Message'

export default {
    props: ['title'],
    components: {
        WindowCard,
        Message
    },
    data: () => ({
        visible: false,
        actions_enabled: true,
        persistent: false
    }),
    methods: {
        show() {
            this.visible = true;
        },
        hide() {
            this.visible = false;
        }
    }
}
</script>