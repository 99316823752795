<template>
    <div>
        <v-row>
            <v-btn class="ma-1" large solo depressed color="secondary" @click="show_import_dialog">
                <v-icon class="mr-2">cloud_upload</v-icon>
                <span class="subtitle-2">Importar participantes</span>
            </v-btn>
        </v-row>
        <v-row>
            <v-col cols="12">
                <DashCard title="Buscar por:" title-icon="search">
                    <v-row class="ma-0">
                        <v-col cols="12" md="3" v-if="$parent.$parent.isin('supers')">
                            <v-label>Participante</v-label>
                            <v-autocomplete 
                                solo flat outlined dense hide-details clearable 
                                v-model="nav_params.participante" 
                                :items="items"  
                                item-key="id" 
                                item-text="nombre_participante" 
                                item-value="id" 
                                prepend-inner-icon="account_circle" 
                                placeholder="Seleccionar participante..."
                                @change="update_filtros" />
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-label>Cursos Externos</v-label>
                            <v-autocomplete 
                                solo flat outlined dense hide-details clearable 
                                v-model="nav_params.curso_externo" 
                                :items="cursos_externos" 
                                item-key="id" 
                                item-text="nombre" 
                                item-value="id" 
                                prepend-inner-icon="folder" 
                                placeholder="Seleccionar curso externo..."
                                @change="update_filtros" />
                        </v-col>
                    </v-row>
                </DashCard>
            </v-col>
            <v-col cols="12">
                <DashCard title="Capacitaciones externas" title-icon="list" body-class="pa-0">
                    <v-data-table dense :headers="headers_tabla_reportes" :items="items" item-key="id" :page="nav_params.page" :items-per-page="20" hide-default-footer>
                        <template v-slot:top>
                            <v-divider />
                            <v-pagination v-model="nav_params.page" :length="pagination_length" @input="go_input" />
                        </template>
                        <template v-slot:item.participante="{ item }">
                            <v-icon left>account_circle</v-icon>{{ item.nombre_participante }}
                        </template>
                        <template v-slot:item.cursos_externos_asociados="{ item }">
                        <div>
                            <!-- Verifica si el listado_cursos_externos es un array o un string -->
                            <template v-if="Array.isArray(item.cursos_externos_detalles)">
                            <!-- Recorre cada curso en la lista listado_cursos_externos y crea un chip para cada uno -->
                            <v-tooltip
                                v-for="(curso, index) in item.cursos_externos_detalles"
                                :key="index"
                                bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-chip
                                        dark
                                        :style="calculateChipColor(curso.fecha_vencimiento)"
                                        class="chip-style"
                                        v-bind="attrs"
                                        v-on="on">
                                        <v-icon left>school</v-icon>
                                        <span class="chip-text">{{ curso.nombre_curso }}</span>
                                    </v-chip>
                                </template>
                                <span>Participante: {{ item.nombre_participante}}</span><br>
                                <span>Curso: {{ curso.nombre_curso }}</span><br>
                                <span>Realización: {{ formatDate(curso.fecha_realizacion) }}</span><br>
                                <span>Vencimiento: {{ formatDate(curso.fecha_vencimiento) }}</span>
                            </v-tooltip>
                            </template>
                            <template v-else>
                            <!-- Muestra un mensaje cuando no hay cursos -->
                            <v-chip dark color="grey">
                                <v-icon left>info</v-icon>
                                {{ item.cursos_externos_detalles }}
                            </v-chip>
                            </template>
                        </div>
                        </template>
                        <template v-slot:item.editar_registro_cursos_externos="{ item }">
                            <v-btn  color="primary" x-small class="subtitle-2 center" :to="{ name: 'editar-cursos-externos' , params: { idParticipante: item.id }}"><v-icon center>mode_edit</v-icon></v-btn>
                        </template>
                        <template v-slot:item.descargar_reporte="{ item }">
                            <v-btn  color="secondary" x-small class="subtitle-2" @click="get(item)"><v-icon center>get_app</v-icon></v-btn>
                        </template>
                        <template v-slot:no-results>
                            <EmptyCard class="my-4" icon="inbox" text="No se han encontrado resultados" />
                        </template>
                        <template v-slot:no-data>
                            <EmptyCard class="my-4" icon="inbox" text="Sin resultados" />
                        </template>
                        <template v-slot:footer>
                            <v-divider />
                            <v-pagination v-model="nav_params.page" :length="pagination_length" @input="go_input" />
                        </template>
                    </v-data-table>
                </DashCard>
            </v-col>
            <DialogImportCursosExternos ref="import_dialog" @upload-success="handleUploadSuccess" @upload-error="handleUploadError"/>
            <DownloadDialog ref="down_dialog" request-type="get" :raw-response="true" @on-download="update_list" />
        </v-row>
        
        <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="-1">
            {{ snackbar.message }}
            <v-btn dark text @click="snackbar.show = false">Cerrar</v-btn>
        </v-snackbar>
    </div>
</template>
<style>
.chip-text {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}
</style>
<script>
import DialogImportCursosExternos from '@/educap/dialogs/DialogImportCursosExternos'
import DashCard from '@/shared/components/DashCard'
import EmptyCard from '@/shared/components/EmptyCard'
import DatePickerText from '@/shared/components/DatePickerText'
import DownloadDialog from '@/shared/components/DownloadDialog'
import { axiosHelper } from '@/helpers'
import moment from 'moment';
import 'moment/locale/es';

export default {
    components: {
        EmptyCard,
        DashCard,
        DatePickerText,
        DialogImportCursosExternos,
        DownloadDialog
    },
    data: () => ({
        headers_tabla_reportes: [
            { value: "participante", text: "Participante" },
            { value: "cursos_externos_asociados", text: "Capacitaciones" },
            { value: "editar_registro_cursos_externos", sortable: false, text: "Editar" },
            // { value: "descargar_reporte", text: "Descargar", sortable: false, width: '10px' }
        ],
        nav_params: {
            page: 1,
            participante: null,
            curso_externo: null,
            inicio:null,
            termino:null,
        },
        colors: {
            red: 'white',
            yellow: 'black',
            green: 'white'
        },
        use_rango: false,
        index: [],
        snackbar: {
            show: false,
            message: '',
            color: '',
            timeout: 3000
        }
    }),
    computed: {
        items() {
            if(!this.index) return [];
            return this.index.results || [];
        },
        participantes() {
            if(!this.index) return [];
            return this.index.participantes || [];
        },
        cursos_externos(){
            return this.index.todos_los_cursos;
        },
        pagination_length() {
            if(!this.index) return 1;
            return this.index.pages;
        }
    },
    methods: {
        calculateChipColor(expirationDate) {
            const today = new Date();
            const expiry = new Date(expirationDate);
            const diffTime = Math.abs(expiry - today);
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
            const commonStyles = {
                margin: '5px',
                width: '150px',
                overflow: 'hidden',
                'text-overflow': 'ellipsis',
                'white-space': 'nowrap'
            };
            if (expiry < today) {
                return { backgroundColor: 'red', color: this.colors.red, ...commonStyles };
            } else if (diffDays <= 21) {
                return { backgroundColor: 'yellow', color: this.colors.yellow, ...commonStyles };
            } else {
                return { backgroundColor: 'green', color: this.colors.green, ...commonStyles };
            }
        },
        update_filtros(e) {
            if(!this.nav_params.inicio && !!this.use_rango) {
                this.nav_params.inicio = moment().format("YYYY-MM-DD").toString();
            } else if(!!this.nav_params.inicio && !this.use_rango) {
                this.nav_params.inicio = null;
            }

            if(!this.nav_params.termino && !!this.use_rango) {
                this.nav_params.termino = moment().format("YYYY-MM-DD").toString();
            } else if(!!this.nav_params.termino && !this.use_rango) {
                this.nav_params.termino = null;
            }
            
            this.go_input();
        },
        go_input(num) {
            if(!!num) {
                this.nav_params.page = num;
            }
            axiosHelper.get('edu/cursos-externos/', null, this.nav_params)
                .then(response => {
                    this.index = response.data;
                })
                .catch(error => {
                    if(error.response.status == 404) {
                        this.go_input(1);
                    }
                });
        },
        update_list() {
            if(this.$parent.$parent.isin('supers')) return;
            this.$parent.$parent.reload_dashboard();
        },
        date_valid(item) {
            var fecha = moment(item.validez);
            return fecha.isValid();
        },
        get(item) {
            // this.$refs.down_dialog.show(`edu/getcertif/${item.id}/`, null, `${item.codename}.pdf`);
        },
        show_import_dialog() {
            this.$refs.import_dialog.show();
        },
        handleUploadSuccess(data) {
            const { usuarios_no_encontrados, total_participantes_en_archivo,cantidad_encontrados,cantidad_no_encontrados } = data;
            let message = `Carga exitosa. Se procesaron ${total_participantes_en_archivo} participantes desde el archivo. ${cantidad_encontrados} participantes cargados.
            `;
            if (usuarios_no_encontrados.length > 0) {
                message += `${cantidad_no_encontrados} no se pudieron cargar: ${usuarios_no_encontrados.join(', ')} 
                Ingréselos como usuarios desde el módulo de usuarios y luego carge el archivo nuevamente.`;
            }
            this.snackbar.message = message;
            this.snackbar.color = 'success';
            this.snackbar.show = true;
            this.go_input();
        },
        handleUploadError(message) {
            this.snackbar.message = message || 'Error en la carga';
            this.snackbar.color = 'error';
            this.snackbar.show = true;
        },
        formatDate(date) {
        return moment(date).locale('es').format('DD MMMM YYYY');
        }
    },
    mounted() {
        this.$parent.$parent.set_title("Cursos Externos", "report");
        this.go_input(1);
    }
}
</script>
